import { render} from 'inferno'
import { on, off, fire } from 'delegated-events'

import ListMenu from '@modules/listmenu'
import { forEach } from '@misc/utilities'

const bootstrapActionlinks = () => {
  on('click', '.actionlinks', e => {
    e.preventDefault()

    const element = e.target.closest('.actionlinks')

    let items:any = []

    forEach(element.querySelectorAll('li a'), el => {
      items.push({
        title: el.textContent,
        href: el.href,
      })
    })

    let rootNode = document.createElement('div')
    rootNode.className = 'actionlinks-container'

    element.parentNode.replaceChild(rootNode, element)

    render(<ListMenu items={items} />, rootNode)
  })
}

export default bootstrapActionlinks
