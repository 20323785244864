import { compatibility } from '@misc/compatibility'
import bootstrapActionlinks from '@bootstrap/actionlinks'
import StartPage from '@misc/startpage'

window.addEventListener('DOMContentLoaded', function() {
  bootstrapActionlinks()
  new StartPage()
})

compatibility()
