/*jslint browser: true*/
/*jslint plusplus: true*/

import {
	createCookie, readCookie, eraseCookie,
	extend, createElementFromHtml, forEach
} from '@misc/utilities'

import BaseButton from '@misc/base-button'
import HoverIntent from '@misc/hoverintent'

class StartPage {

  public moduleBlockDefaults
  public moduluebersichtElem
  public progressElem
  public buttons
  public control_buttons

	constructor() {

		this.moduleBlockDefaults = {
			'theory': 'open',
			'practice': 'closed',
		}

		this.moduluebersichtElem = document.getElementById('moduluebersicht')
		this.progressElem = document.getElementById('progress')

		if(this.moduluebersichtElem) {
			this.buttons = {}
			this.initControlsButtons()
			this.initCourseOverview()
		}
	}

	initControlsButtons() {
		const self = this

		// Instantiate and append all buttons
		let controlButtonsHtml = ''

		controlButtonsHtml += '<div class="viewcontrols btn-group">';
		controlButtonsHtml += '    <a class="button icon" id="ExpandUnits"><div class="icon"></div></a>';
		controlButtonsHtml += '</div>';

		this.control_buttons = createElementFromHtml(controlButtonsHtml)

		const controlsElem = document.querySelector('#Top #Controls')

		controlsElem.insertBefore(this.control_buttons, controlsElem.firstChild)

		this.buttons.expandUnits = new BaseButton(

			document.getElementById('ExpandUnits'),
			'expandUnits',

			{
				title: 'Details der Lerneinheiten ein-/ausklappen',

				target_cssclass: 'open_lock',

				actionPressed: function () {
					[ '.lerneinheit', 'fall' ].forEach(selector => {
						forEach(self.moduluebersichtElem.querySelectorAll(selector), element => {
							element.classList.add(this.target_cssclass)
						})
					})
				},

				actionReleased: function () {
					[ `.lerneinheit.${this.target_cssclass}`, `.fall.${this.target_cssclass}` ].forEach(selector => {
						forEach(self.moduluebersichtElem.querySelectorAll(selector), element => {
							element.classList.remove(this.target_cssclass)
						})
					})
				}
			}
		);
	}

	initCourseOverview() {
		new HoverIntent(
			'#moduluebersicht .unit-type-theory',
			e => {
				const element = e.target.closest('.lerneinheit')
				if(element) {
					element.classList.add('open')
				}
			},
			e => {
				const element = e.target.closest('.lerneinheit')
				if(element) {
					element.classList.remove('open')
				}
			}
		)


		const moduleBlocks = this.moduluebersichtElem.querySelectorAll('.module-block')

    forEach(moduleBlocks, block => {
      this.courseOverviewEvenHeights(block)
    })
	}

	courseOverviewEvenHeights(moduleBlock) {

		let rowSizes,
			currentRow,
			currentElem,
			currentRowHeight,
			resizeElem,
			iRow,
			minSize = 70,
			maxSize,
			titleElem,
			i,
			j;

		// Module loop
		let modules, rows

		rowSizes = []

		modules = moduleBlock.querySelectorAll('.module')

		forEach(modules, elem => {

			iRow = 0;

			// Learning units loop
			rows = elem.querySelector('.content').children

			forEach(rows, rowElem => {

				if (!rowSizes[iRow]) {
					rowSizes[iRow] = {
						elements: [],
						maxSize: minSize
					};
				}

				currentRow  = rowSizes[iRow]
				currentRow.elements.push(rowElem)

				currentRowHeight = rowElem.getBoundingClientRect().height


				if (currentRow.maxSize < currentRowHeight) {
					currentRow.maxSize = currentRowHeight
				}

				iRow++
			});
		});

		// set row sizes
		forEach(rowSizes, rowSize => {
			forEach(rowSize.elements, (rowSizeElement, i) => {
				titleElem = rowSizeElement.querySelector('.title')
				resizeElem = titleElem || rowSizeElement
				maxSize = rowSize.maxSize
				resizeElem.style.height = `${maxSize}px`
			})
		})
	}
}

export default StartPage
