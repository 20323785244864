
import 'classlist-polyfill'
import 'element-closest'

import 'core-js/es6/weak-map'
import 'core-js/es6/set'
import 'core-js/es6/map'
import 'core-js/es6/promise'

export function compatibility() {

  window.Set = window.Set || Set
  window.Map = window.Map || Map
  window.Promise = window.Promise || Promise

  if (!Element.prototype.matches)
  {
    var ep = Element.prototype;

    if (ep.webkitMatchesSelector) // Chrome <34, SF<7.1, iOS<8
    ep.matches = ep.webkitMatchesSelector;

    if (ep.msMatchesSelector) // IE9/10/11 & Edge
    ep.matches = ep.msMatchesSelector;

    if (ep.mozMatchesSelector) // FF<34
    ep.matches = ep.mozMatchesSelector;
  }
}

