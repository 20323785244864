import {
	createCookie, readCookie, eraseCookie,
	extend, createElementFromHtml, forEach
} from '@misc/utilities'

class BaseButton {

  public el
  public name
  public title
  public status

  public actionPressed
  public textPressed
  public classPressed

  public actionReleased
  public textReleased
  public classReleased

  public cookieExpires

  public defaultStatus

	constructor(elem, name, options) {

		this.name = name
		this.el = elem

		let defaults = {
			classPressed: 'pressed',
			defaultStatus: 'released',
			title: 'Set ' + this.name + ' active/inactive',
			textPressed: null,
			textReleased: null,
			actionPressed: null,
			actionReleased: null
		},
			initStatus,
			cookieVal = this.getCookieVal()

		options = extend(defaults, options)
		extend(this, options)

		// events
		this.el.addEventListener('click', e => {
			e.preventDefault()
			this.toggleStatus()
		})

		this.el.title = this.title

		// init status depending on cookie
		if (cookieVal) {
			initStatus = cookieVal === 'released' ?
				this.setStatusReleased : this.setStatusPressed
		} else {
			initStatus = this.defaultStatus === 'released' ?
				this.setStatusReleased : this.setStatusPressed
		}

		initStatus.call(this)
	}

	getCookieVal() {
		const cookie = readCookie(this.name)
		return (cookie === "") ? false : cookie
	}

	setCookieVal(v) {
		createCookie(this.name, v, this.cookieExpires)
	}

	setStatusPressed() {

		this.status = 'pressed'

		if (!this.el.classList.contains(this.classPressed)) {
			this.el.classList.add(this.classPressed)
		}

		if (this.textPressed) {
			this.el.textContent = this.textPressed
		}

		this.setCookieVal(this.status)

		if (this.actionPressed) {
			this.actionPressed()
		}
	}

	setStatusReleased() {

		this.status = 'released';

		if (this.el.classList.contains(this.classPressed)) {
			this.el.classList.remove(this.classPressed)
		}

		if (this.textPressed) {
			this.el.textContent = this.textReleased
		}

		this.setCookieVal(this.status)

		if (this.actionReleased) {
			this.actionReleased()
		}
	}

	toggleStatus() {

		if (this.status === 'pressed') {
			this.setStatusReleased()
		} else if (this.status === 'released') {
			this.setStatusPressed()
		}
	}
}

export default BaseButton
